import Swiper, { Navigation, Pagination, Autoplay, Controller, Lazy } from 'swiper';
import IMask from 'imask'

const SWIPER_CONFIGS = {
  index: {
    modules: [Navigation, Pagination, Autoplay, Controller, Lazy],
    autoplay: {
      delay: 3000,
      pauseOnMouseEnter: true,
    },
    slidesPerView: 1,
    loop: true,
    pagination: {
      el: "#index-slider .swiper-pagination",
      clickable: true,
    },
  },
  products: {
    modules: [Navigation, Pagination, Autoplay, Controller, Lazy],
    spaceBetween: 15,
    slidesPerView: 1,
    pagination: {
      el: '.swiper__dots',
      type: 'bullets',
      clickable: true
    },
    breakpoints: {
      476: {
        slidesPerView: 2,
      },
      768: {
        slidesPerView: 3,
      },
      992: {
        slidesPerView: 4,
      },
      1200: {
        slidesPerView: 5,
      },
    },
  },
  portfolio: {
    modules: [Navigation, Pagination, Autoplay, Controller, Lazy],
    spaceBetween: 15,
    lazy: {
      loadPrevNext: true,
    },
    loop: true,
    watchOverflow: true,
    autoplay: {
      delay: 3000,
      pauseOnMouseEnter: true,
    },
    pagination: {
      el: '.swiper__dots',
      type: 'bullets',
      clickable: true
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 2
      },
      1200: {
        slidesPerView: 3,
      },
    },
  },
  brands: {
    modules: [Navigation, Pagination, Autoplay, Controller, Lazy],
    spaceBetween: 10,
    slidesPerView: 2,
    lazy: {
      loadPrevNext: true,
    },
    loop: true,
    watchOverflow: true,
    autoplay: {
      delay: 3000,
      pauseOnMouseEnter: true,
    },
    pagination: {
      el: '.brand-list .swiper__dots',
      type: 'bullets',
      clickable: true
    },
    breakpoints: {
      476: {
        slidesPerView: 3,
      },
      768: {
        slidesPerView: 4,
      },
      992: {
        slidesPerView: 5,
      },
      1200: {
        slidesPerView: 6,
      },
    },
  },
}

document.addEventListener("TwinsparkLoaded", () => {
  console.log('TwinsparkLoaded!')
  window.twinspark.func({
    initImask: function(o) {
      console.log('init IMask!')
      new IMask.InputMask(o.el, {
        mask: "+0 (000) 000-00-00",
      })
      o.el.addEventListener("focus", function(e) {
        const el = this
        setTimeout(function() {
          el.selectionStart = el.selectionEnd = 10000
        }, 0)
      })
    },
    initNoUiSlider: function(o) {
      window.initNoUiSlider(document.querySelectorAll(".nouislider"))
    },
    sendEvent: function(event, o) {
      if (typeof (dataLayer) != 'undefined') {
        const data = {
          event: 'UNIVERSAL_EVENT',
          eventName: event
        }

        if (o.el.dataset.pseudoLeadId) {
          data['pseudo_lead_id'] = o.el.dataset.pseudoLeadId
        }

        dataLayer.push(data)

        if (o.el.dataset.href) {
          window.open(o.el.dataset.href, o.el.getAttribute('target'))
        } else if (o.el.tagName == 'FORM') {
          o.el.submit()
        }
      }
    },
    callRequest: function(o) {
      grecaptcha.ready(function() {
        grecaptcha.execute(RECAPTCHA_PUBLIC_KEY, { action: 'submit' }).then(function(token) {
          let req = twinspark.makeReq(
            o.el,
            undefined,
            false,
            {
              method: 'post',
              url: '/ts/call-request/create/',
              data: [
                ['captcha', token],
                // ['csrfmiddlewaretoken', o.el.querySelector('[name=csrfmiddlewaretoken]').value],
                ['name', o.el.querySelector('[name=name]').value],
                ['phone', o.el.querySelector('[name=phone]').value]
              ].concat(o.el.querySelectorAll('[type=hidden]').values().map(n => [n.name, n.value]).toArray())
            }
          );
          twinspark.executeReqs(req);
        });
      });
      return false;
    },
    changeDeliveryMethod: function(o) {
      const el = o.el
      const deliveryMethodBtn = el.parentNode.querySelectorAll(".component_delivery-method")
      deliveryMethodBtn.forEach(i => i.classList.remove("_active"))
      el.classList.add("_active")
      document.getElementById("delivery_type").value = el.getAttribute("value")
    },
    toggleAside: function(o) {
      let open = document.body.classList.toggle('_rim-filter-toggle');
      if (open) {
        sessionStorage.setItem('asideStatus', 'closed');
      } else {
        sessionStorage.setItem('asideStatus', 'open');
      }
    },
    dropdownsClose: function(o) {
      document.querySelectorAll(`[class*=dropdown_open]`).forEach(el => {
        if (el != o.el)
          el.classList.forEach(cls => {
            if (cls.endsWith("dropdown_open"))
              el.classList.remove(cls)
          })
      })
    },
    dropdownSelect: function(txt, val, o) {
      let el = o.el
      const dropdown = el.parentNode.parentNode
      const children = dropdown.children
      const input = children[0]
      const button = children[1]
      const cls = dropdown.classList[0]
      dropdown.classList.remove(cls)
      input.value = val
      button.innerText = txt
      twinspark.trigger(o.el.closest("form"), "submit")
    },
    switcherChange: function(o) {
      let el = o.el
      let stepEl = el.parentElement.querySelector("[name=step]")
      let step = stepEl && parseInt(stepEl.value) || 1

      const countItem = el.parentNode.querySelector("input[name=quantity]")
      let count = Number(countItem.value)

      if (el.classList.contains("_plus")) {
        count += step
      } else if (el.classList.contains("_minus")) {
        count = count - step || 4
      }

      countItem.value = count
    },
    keepOnlyOneSelectedAndSubmit: function(o) {
      const container = o.el.closest("div")
      const all = container.querySelectorAll("input")
      const checked = container.querySelectorAll("input:checked")
      const form = o.el.closest("form")
      if (all.length == checked.length || checked.length == 0) {
        all.forEach(el => el.parentNode.remove())
      }
      twinspark.trigger(form, 'submit')
    },
    clearDependentCheckboxesAndSubmit: function(sel, o) {
      document.querySelectorAll(sel).forEach(el => el.checked = false)
      twinspark.trigger(o.el.closest('form'), 'submit')
    },
    filterCheckboxItems: function(o) {
      const val = o.el.value
      o.el.parentNode.querySelectorAll('.checkbox-items label').forEach(label => {
        const input = label.querySelector('input')
        if (input.disabled) return
        const el = label.querySelector('a')
        if (el.innerText.toLowerCase().indexOf(val.toLowerCase()) == -1) {
          label.classList.add('d-none')
          label.classList.remove('d-flex')
        } else {
          label.classList.add('d-flex')
          label.classList.remove('d-none')
        }
      })
    },
    resetFilters: function(o) {
      o.el.classList.add('btn_bg')
      const form = o.el.closest('form')
      form.reset()
      form.querySelectorAll('input[type=hidden], select').forEach(el => el.value = '')
      form.querySelectorAll('input[type=checkbox]').forEach(el => el.checked = false)
      twinspark.trigger(form, 'submit')
    },
    togglebox: function(o) {
      var parent = null
      if (o.el.type == 'label') {
        parent = o.el
      } else {
        parent = o.el.parentElement
      }
      var box = o.el.parentElement.querySelector('[type="checkbox"]');
      box.checked = !box.checked;
    },
    submit: function(o) {
      twinspark.trigger(o.el.closest('form'), 'submit');
      // window.submitFilter(o.el.closest('form'))
    },
    scrollToTop: function(o) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    initSwiper: function(section, o) {
      new Swiper(o.el, SWIPER_CONFIGS[section])
    },
  })
})
